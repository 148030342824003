import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/App';

if (process.env.NODE_ENV === 'production') {
  require('./agents/newrelic');
}

ReactDOM.render(<App />, document.getElementById('root'));
