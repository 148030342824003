import React from 'react';

import useReturnPortalSize from '../../hooks/useReturnPortalSize';
import OrderInfo from './OrderInfo';
import { isDesktop } from '../../sizeHelpers';
import { ItemToSubmit, Order } from '../../types';
import ViewHeading from '../page-layout/ViewHeading';
import LayoutWithAction from '../LayoutWithAction';
import ActionButton from './ActionButton';

type Props = {
  order?: Order;
  buttonText: string;
  buttonName: string;
  isButtonLoading?: boolean;
  hasButtonError?: boolean;
  onButtonClick: (items: ItemToSubmit[]) => void;
};

function OrderWithoutItems(props: Props) {
  const { order, isButtonLoading, hasButtonError, buttonText, buttonName, onButtonClick } = props;

  const size = useReturnPortalSize();

  const headingElement = <ViewHeading>overview_heading</ViewHeading>;
  const infoElement = <OrderInfo order={order} orientation={isDesktop(size) ? 'row' : 'column'} />;
  const button = (
    <ActionButton
      onClick={() => onButtonClick([])}
      isLoading={isButtonLoading}
      hasError={hasButtonError}
      name={buttonName}
    >
      {buttonText}
    </ActionButton>
  );

  return <LayoutWithAction heading={headingElement} info={infoElement} content={<div />} button={button} />;
}

export default React.memo(OrderWithoutItems);
