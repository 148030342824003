import React from 'react';
import styled from '@emotion/styled';

import WhatsNext from './WhatsNext';
import WhatsNextItem from './WhatsNextItem';
import Button from '../common/Button';
import { ReturnConfirmationViewProps } from './types';
import { useSettings } from '../../hooks/useSettings';
import PageView from '../PageView';
import ConfirmationContactUs from './ConfirmationContactUs';
import CreateAdditionalLabel from './CreateAdditionalLabel';
import { useGeneralConfirmationMessage } from './useConfirmationMessage';
import useReturnPortalType from '../../hooks/useReturnPortalType';
import ConfirmationProductsList from './ConfirmationProductsList';

const ButtonLink = styled('a')`
  text-decoration: none;
`;

function NormalConfirmationView(props: ReturnConfirmationViewProps) {
  const { accentColor, nextSteps = [] } = useSettings();
  const { returnRequest, returnState } = props;
  const { items, labelUrl } = returnRequest;
  const { isLite } = useReturnPortalType();

  const message = useGeneralConfirmationMessage('confirmation_message', returnState, returnRequest);

  return (
    <PageView heading="confirmation_heading" message={message} intent="success">
      <ConfirmationContactUs />
      <WhatsNext>
        {nextSteps.map((step, index) => (
          <WhatsNextItem key={index} index={index + 1} title={step.title} description={step.description}>
            {step.hasLabelButton && (
              <ButtonLink href={labelUrl} target="_blank" rel="noopener noreferrer">
                <Button name="downloadLabel" color={accentColor}>
                  {step.buttonText}
                </Button>
              </ButtonLink>
            )}
          </WhatsNextItem>
        ))}
      </WhatsNext>
      {returnState === 'exists' && <CreateAdditionalLabel color={accentColor} />}
      {!isLite && <ConfirmationProductsList heading="confirmation_productsListHeading" items={items} />}
    </PageView>
  );
}

export default React.memo(NormalConfirmationView);
