import React, { ReactElement, useEffect, useMemo } from 'react';
import { useAsyncFn, useDeepCompareEffect } from 'react-use';

import { isApiError } from '../apiHelpers';
import { ReturnPortalContext, ReturnPortalContextValue } from '../contexts/ReturnPortalContext';
import { getReturnPortalByHash, getReturnPortalByBrandedUrl } from '../api';
import ErrorPage from './error/ErrorPage';
import Error404Page from './error/Error404Page';
import { heapAddEventProperties } from '../heap';
import Spinner from './common/Spinner';
import colors from './common/colors';
import { isBrandedUrlResult, isGeneralUrlResult, parseReturnPortalUrl, UrlParseResult } from '../urlHelpers';
import { ReturnPortal, LanguageTranslations, ReturnPortalTranslations } from '../types';
import { mergeTranslationsWithReasons } from '../reasonsHelpers';

type Props = {
  hostname: string;
  pathname: string;
  children: ReactElement;
};

function SettingsLoader(props: Props) {
  const { hostname, pathname } = props;
  const urlParseResult = useMemo(() => parseReturnPortalUrl(hostname, pathname), [hostname, pathname]);

  const [state, fetchReturnPortal] = useAsyncFn(urlParseResult => getReturnPortal(urlParseResult), []);
  const { value: returnPortal, loading, error } = state;

  useDeepCompareEffect(() => {
    fetchReturnPortal(urlParseResult);
  }, [urlParseResult]);

  useEffect(() => {
    if (returnPortal) {
      const { shopId, shopName, language, countryCode } = returnPortal;
      heapAddEventProperties({ shopId, shopName, language, countryCode });
    }
  }, [returnPortal]);

  const translationsByLanguage = useMemo(() => {
    if (!returnPortal) {
      return {};
    }

    const translations = groupTranslationsWithLanguage(returnPortal.language, returnPortal.translations);
    return mergeTranslationsWithReasons(returnPortal.language, translations, returnPortal.settings.reasonOptions);
  }, [returnPortal]);

  if (loading) {
    return <Spinner color={colors.blue} />;
  }

  if (error) {
    if (isApiError(error) && error.response?.status === 404) {
      return <Error404Page />;
    }

    if (isApiError(error)) {
      return <ErrorPage>{error.response?.status.toString()}</ErrorPage>;
    }

    return <ErrorPage>{error.message}</ErrorPage>;
  }

  if (!returnPortal) {
    return null;
  }

  const { shopId, settings, language, countryCode, availableLanguages, isLite } = returnPortal;

  const contextData: ReturnPortalContextValue = {
    shopId,
    settings,
    language,
    countryCode,
    translations: translationsByLanguage,
    hash: returnPortal.hash,
    availableLanguages,
    hasBrandedUrl: isBrandedUrlResult(urlParseResult),
    isLite,
  };

  return <ReturnPortalContext.Provider value={contextData}>{props.children}</ReturnPortalContext.Provider>;
}

function getReturnPortal(result: UrlParseResult): Promise<ReturnPortal> {
  if (isBrandedUrlResult(result)) {
    return getReturnPortalByBrandedUrl(result.subdomain, result.route);
  }

  if (isGeneralUrlResult(result)) {
    return getReturnPortalByHash(result.hash);
  }

  return Promise.reject(new Error('Wrong URL'));
}

function groupTranslationsWithLanguage(language: string, translations: LanguageTranslations): ReturnPortalTranslations {
  return { [language]: translations };
}

export default React.memo(SettingsLoader);
